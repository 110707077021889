const breakKeys = (): void => {
  const { $i18n: i18n } = useNuxtApp()
  const styles = `
      * {
        word-break: break-all !important;
      }
      `

  if (i18n.locale.value !== 'keys') {
    const styleSheet = document.getElementById('keysClass')

    if (styleSheet) {
      styleSheet.remove()
    }
  } else {
    const styleSheet = document.createElement('style')

    styleSheet.id = 'keysClass'
    styleSheet.textContent = styles

    document.head.appendChild(styleSheet)
  }
}

export {
  breakKeys
}
