<template>
  <section
    class="border-b border-17-Grey-24%"
  >
    <div
      class="px-5 py-4 flex justify-between items-center lg:px-0 max-w-[1024px] mx-auto w-full xl:max-w-[1152px] 2xl:max-w-[1140px]"
    >
      <NuxtLink
        external
        to="https://www.cycle.eco/"
      >
        <IconLoader
          name="logo"
          class="h-5"
          alt="Cycle logo"
        />
      </NuxtLink>
      <LanguageSwitcher />
    </div>
  </section>
</template>
