<template>
  <client-only>
    <teleport
      to="body"
    >
      <div
        style="position: fixed; z-index: 30"
      >
        <vue-cookie-comply
          ref="cookieConsentRef"
          :header-description="$t('cookie_consent.header_description')"
          :preferences="preferences"
          :grey-out-body="true"
          :show-accept-all-in-modal="true"
          :preferences-label="$t('cookie_consent.preferences')"
          :accept-all-label="$t('cookie_consent.accept_all')"
          :reject-all-label="$t('cookie_consent.reject_all')"
          @on-accept-all-cookies="startAllServices"
          @on-save-cookie-preferences="startSelectedServices"
          @on-cookie-comply-mount="startConsentedServices"
        />
      </div>
    </teleport>
  </client-only>
</template>

<script lang="ts" setup>
import type { Component } from 'vue'
import { ref, watch } from 'vue'
import {
  enableFB,
  enableGA,
  cookieConsent,
  acceptedServices,
  enableGM
} from '~/utils/tracking-utils'

const { $i18n: i18n } = useNuxtApp()

const cookieConsentRef = ref<Component | null>(null)

watch(
  cookieConsentRef,
  (newVal) => {
    if (!newVal) {
      return
    }
    cookieConsent.value = cookieConsentRef.value
  },
  {
    deep: true
  }
)

const preferences = [
  {
    title: i18n.t('cookie_consent.preferences.title'),
    items: [
      {
        label: i18n.t('cookie_consent.preferences.item1.label'),
        description: i18n.t('cookie_consent.preferences.item1.description'),
        value: 'ga'
      },
      {
        label: i18n.t('cookie_consent.preferences.item2.label'),
        description: i18n.t('cookie_consent.preferences.item2.description'),
        value: 'fb'
      },
      {
        label: i18n.t('cookie_consent.preferences.item3.label'),
        description: i18n.t('cookie_consent.preferences.item3.description'),
        value: 'gm'
      }
    ]
  }
]

const config = useRuntimeConfig()

const startSelectedServices = (acceptedItems: Array<string>): void => {
  acceptedServices.value = acceptedItems
  if (!['production', 'development', 'preview'].includes(config.public.vercelEnv)) {
    return
  }
  acceptedItems.forEach(((item: string) => {
    switch (item) {
      case 'ga':
        enableGA()
        break
      case 'gm':
        enableGM()
        break
      case 'fb':
        enableFB()
        break
      default:
        break
    }
  }))
}

const startAllServices = (): void => {
  acceptedServices.value = ['all']
  if (!['production', 'development', 'preview'].includes(config.public.vercelEnv)) {
    return
  }
  enableGA()
  enableGM()
  enableFB()
}

const startConsentedServices = (services: Array<string>): void => {
  if (!services.length) {
    return
  }
  if (services.includes('all')) {
    startAllServices()
  }
  startSelectedServices(services)
}
</script>

<style lang="scss">
/* Render cookie comply out of viewport to reduce LCP */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes slide-up {
  0% {
    transform: translateY(110vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

.cookie-comply-body-grey-out {
  @apply fixed top-0 right-0 bottom-0 left-0 bg-black opacity-0 w-screen h-screen overflow-hidden;
  animation: fade-in 1s forwards;
}
.cookie-comply {
  @apply fixed bottom-4 mx-4 bg-[#f2f1f0] p-8 md:p-14 rounded-[60px] flex justify-between space-y-12 md:space-y-0 md:space-x-28 flex-col md:flex-row overflow-y-auto;
  max-height: calc(100svh - 2rem);
  width: calc(100% - 32px);
  animation: slide-up 1s forwards;
  transform: translateY(110vh);

  &--modal-open {
    filter: brightness(0.3);
  }

  &__header {
    @apply flex flex-col space-y-4;
  }

  &__header-description {
    @apply text-sm md:text-base;
  }

  &__header-description a {
    @apply underline;
  }

  &__item-description {
    @apply w-full
  }

  h3 {
    @apply font-roc-grotesk-bold text-3xl md:text-4xl text-black uppercase;
  }

  &__actions {
    @apply flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-4;
  }

  &__button {
    @apply rounded-full flex items-center border border-black py-1.5 md:py-5 px-6 whitespace-nowrap w-full justify-center text-base md:text-xl md:text-base hover:bg-[#E0DFDE];

    &-accept {
      @apply border-0 bg-16-Agency-500 text-white hover:bg-16-Agency-800 border border-16-Agency-500 hover:border-16-Agency-800;
    }
  }

  &__close-icon {
    @apply absolute top-10 right-12 w-6 w-6 cursor-pointer
  }

  &__modal-footer {
    @apply mt-6 flex justify-between space-y-3 md:space-y-0 space-x-0 md:space-x-3 flex-col md:flex-row;
  }

  &__modal-switches {
    @apply flex flex-wrap justify-between items-center mb-6;
  }

  &__modal-content a {
    @apply underline
  }
}
.cookie-comply__modal {
  @apply fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center z-30;
  max-height: 100svh;

  &-inner {
    @apply overflow-y-auto h-full;
  }

  &-middle {
    @apply bg-[#f2f1f0] rounded-[60px] relative h-full;
    max-width: 509px;
    padding: 88px 72px 72px
  }

  header {
    h3 {
      @apply text-center font-roc-grotesk-bold text-3xl md:text-4xl mb-2 text-black uppercase mb-10;
    }
  }
  main {
    h2 {
      @apply font-roc-grotesk-medium text-2xl text-black;
    }
    p {
      @apply text-black leading-5 font-sans;
    }
    h3 {
      @apply font-roc-grotesk-bold text-black text-2xl mt-6;
    }
    label {
      @apply mt-6
    }
  }

  // Begin Switch
  .cookie-comply-switch {
    @apply relative inline-block;
    width: 33px;
    height: 21px;
  }

  .cookie-comply-switch input {
    @apply opacity-0 w-0 h-0
  }

  .cookie-comply-slider {
    @apply absolute cursor-pointer top-0 right-0 bottom-0 left-0;
    background-color: #C3C3C2;
    transition: 0.4s;
  }

  .cookie-comply-slider:before {
    @apply absolute h-3.5 w-3.5 left-1 bottom-1 bg-white;
    content: '';
    transition: 0.4s;
  }

  input:checked + .cookie-comply-slider {
    @apply bg-16-Agency-500;
  }

  input:checked + .cookie-comply-slider:before {
    transform: translateX(12px);
  }

  .cookie-comply-slider.cookie-comply-round {
    @apply rounded-[50px];
  }

  .cookie-comply-slider.cookie-comply-round:before {
    border-radius: 50%;
  }

  .cookie-comply-required {
    @apply cursor-not-allowed;
  }

  input:checked + .cookie-comply-required.cookie-comply-slider {
    background-color: #C3C3C2;
  }
  // End Switch
}
</style>
